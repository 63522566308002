import React, { useState } from 'react';
import { Navbar, Nav, Container,Image } from "react-bootstrap";
import { useScrollSection } from 'react-scroll-section';
import './../../css/header.css';

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar collapseOnSelect expanded={expanded} expand="lg" fixed="top" bg="light" variant="light" >
            <Container className="header">
                <Navbar.Brand href="/"><Image src={`${process.env.PUBLIC_URL}/img/hanover-logo.webp`} alt="logo" /></Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav onClick={() => setExpanded(false)} >
                        <Nav.Link onClick={useScrollSection('home').onClick} selected={useScrollSection('home').selected} >INICIO</Nav.Link>
                        <Nav.Link onClick={useScrollSection('business').onClick} selected={useScrollSection('business').selected} >EMPRESA</Nav.Link>
                        <Nav.Link onClick={useScrollSection('works').onClick} selected={useScrollSection('works').selected} >OBRAS</Nav.Link>
                        <Nav.Link onClick={useScrollSection('experiences').onClick} selected={useScrollSection('experiences').selected} >EXPERIENCIA</Nav.Link>
                        <Nav.Link onClick={useScrollSection('services').onClick} selected={useScrollSection('services').selected} >SERVICIOS</Nav.Link>
                        <Nav.Link onClick={useScrollSection('clients').onClick} selected={useScrollSection('clients').selected} >CLIENTES</Nav.Link>
                        <Nav.Link onClick={useScrollSection('contact').onClick} selected={useScrollSection('contact').selected} >CONTACTO</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default Header;