import React, { lazy, Suspense } from 'react';
import { ScrollingProvider } from 'react-scroll-section';
import './css/App.css';
import Header from './component/global/header';
import Footer from './component/global/footer';

const Content = lazy(() => import('./component/global/content'));



const App = () => {
  return (
    <Suspense fallback={<div className="loading"></div>}>
        <ScrollingProvider>
          <Header />
          <Content />
          <Footer />
        </ScrollingProvider>
    </Suspense>
  )
}

export default App;
