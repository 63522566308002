import './../../css/footer.css';

const Footer = () => {
  const currentTime = new Date();
  const year = currentTime.getFullYear()
  return (
    <div className="Footer">
      <p>© {year} Todos los derechos reservados <strong>HANOVER CONSTRUCCIONES SPA</strong></p>
    </div>
  );
}
export default Footer;